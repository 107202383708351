<template>
  <div>
    <b-row>
      <b-col lg="12">
        <p class="subtitle">PASO A PASO DE LO QUE DEBES HACER</p>

        <h6>
          <a href="https://youtu.be/WVt2Ch4vb8c" target="_blank">Clic aquí para ver video de instrucciones</a>
        </h6>
        <br>  
        <p>
          <CBadge 
            shape="pill" 
            color="warning" 
            class="float-let">1
          </CBadge> 
          Regístrate haciendo clic          
          <b-link @click="goTo('register')">aquí.</b-link>
        </p>
        <!-- <p>
          <CBadge 
            shape="pill" 
            color="warning" 
            class="float-let">2
          </CBadge> 
          Inicia sesión
        </p> -->
        <p>
          <CBadge 
            shape="pill" 
            color="warning" 
            class="float-let">2
          </CBadge>  
          Completa tu perfil en: Configuración -> Mi perfil.
        </p>
        <p>
          <CBadge 
            shape="pill" 
            color="warning" 
            class="float-let">3
          </CBadge> 
          Crea el perfil de tu mascota en: Configuración -> Mis mascotas.
        </p>
        <p>
          <CBadge 
            shape="pill" 
            color="warning" 
            class="float-let">4
          </CBadge> 
          Lee el código QR de tu placa para asignarla a tu mascota (con tu sesión iniciada).
        </p>
        <p>
          <span>
            <CIcon 
              name="cilAnimal" 
              height="16" 
              alt="Logo" 
              style="color:#c79c00;"/> &nbsp
            <!-- <CIcon 
              name="cilStar" 
              height="16" 
              alt="Logo" 
              style="color:#c79c00;"/>  -->
            ¡Listo así de fácil tu mascota está mas segura en caso de pérdida!            
          </span>
        </p>
        
        <hr>
        <p>
          <CIcon 
              name="cilHandPointRight" 
              height="16" 
              alt="Logo" 
              style="color:#001b76"/> 
          Si necesitas ayuda en este proceso, contáctanos a la línea Go Guau.
        </p>
        <CButton
          class
          name="Whatsapp"
          size="md"
          color="xing"
          target="_blank"
          @click.prevent="whatsapp()"
        >
          <CIcon size="sm" :name="'cib-Whatsapp'"/> Whatsapp
        </CButton>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "TagRegister",
  data () {
    return {
      wp: 'https://api.whatsapp.com/send?phone=573194115792&text=Hola, ',
    }
  },
  methods: {
    goTo(to) {
      if(to == 'register') {
        let route = this.$router.resolve({path: '/pages/register'});
        window.open(route.href, '_blank');
      }
    },
    whatsapp(){          
      let message = this.wp +
        ' necesito ayuda con la activación de la placa QR.'; 
      window.open(message, '_blank');
    },
  }
};
</script>

<style scoped>
.subtitle {
  font: bold 16px "Arial";
  /* color: #1155cc; */
  line-height: 16px;
}
</style>