<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <!-- alert that shows the tagId is not registered -->
    <b-alert v-model="flagTagNotRegistered" variant="warning" dismissible>
      <b-row>
        <b-col lg="12">El id de la tarjeta no se encuentra registrado.</b-col>
        <b-col lg="12">
          <br />Contáctanos para ayudarte con tu caso
          <CButton
            class
            name="Whatsapp"
            size="sm"
            color="xing"
            target="_blank"
            @click.prevent="whatsapp('help')"
          >
            <CIcon size="lg" :name="'cib-Whatsapp'" />Whatsapp
          </CButton>
        </b-col>
      </b-row>
    </b-alert>
    <b-alert v-model="flagTagActivated" variant="success" dismissible>
      <b-row>
        <b-col lg="12">
          !Tu tarjeta ha sido activada con éxito!
          El perfil digital de tu mascota está disponible.
        </b-col>
      </b-row>
    </b-alert>
    <b-alert v-model="flagIdIsNotHash" variant="danger" dismissible>
      <b-row>
        <b-col lg="12">El id no es válido. Escanea nuevamente el código QR de tu placa.</b-col>
        <b-col lg="12">
          <br />Si tu problema persiste contáctanos para ayudarte con tu caso
          <CButton
            class
            name="Whatsapp"
            size="sm"
            color="xing"
            target="_blank"
            @click.prevent="whatsapp('help')"
          >
            <CIcon size="lg" :name="'cib-Whatsapp'" />Whatsapp
          </CButton>
        </b-col>
      </b-row>
    </b-alert>

    <!-- card with the steps to activate de tag -->
    <b-row v-if="infoModal">
      <b-col lg="10" offset-lg="1">
        <CCard accent-color="info">
          <CCardHeader accent-color="primary">¡Bienvenido! Activa tu placa en bombas.</CCardHeader>
          <CCardBody>
            <tag-register />
          </CCardBody>
        </CCard>
      </b-col>
    </b-row>
    <!-- Card to associate tag form -->
    <b-row v-if="flagAssociateTag">
      <b-col lg="10" offset-lg="1">
        <CCard accent-color="warning">
          <CCardHeader>
            <strong>Estas a un solo paso para activar tu tarjeta</strong>
          </CCardHeader>
          <CCardBody>
            <b-row v-if="error">
              <b-col cols="12">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-for="error_message in errors"
                  v-bind:key="error_message"
                >{{error_message}}</div>
              </b-col>
            </b-row>
            <label for="radio-group-pets">Selecciona la mascota con la que vas a asociar la tarjeta</label>
            <b-form-group>
              <b-form-radio-group
                id="radio-group-pets"
                v-model.trim="$v.petSelected.$model"
                :options="optionsPetsToSelect"
                name="radio-options-2"
              ></b-form-radio-group>
            </b-form-group>
          </CCardBody>
          <CCardFooter>
            <b-row>
              <b-col lg="4" md="4" sm="6" style="text-align:center; margin-bottom:1rem;">
                <CButton
                  class="s-bg s-bg-text mr-1"
                  style="width:100%;"
                  @click="submit"
                >Activar tarjeta</CButton>
              </b-col>
            </b-row>
          </CCardFooter>
        </CCard>
      </b-col>
    </b-row>
    <!-- Pet Profile -->
    <b-row v-if="flagPetProfile">
      <b-col lg="8" offset-lg="2" md="8" offset-md="2">
        <CCard accent-color="info">
          <CCardHeader>
            <strong>Perfil de mascota</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col lg="12" style="text-align:center;">
                <h4>Soy  {{pet.name}}, ayúdame a regresar a casa</h4>
                <p>Contacta a mi dueño al: {{pet.phone_number}}</p>
              </b-col>

              <b-col lg="12">
                <!-- <p></p> -->
              </b-col>

              <b-col lg="6" class="text-center">
                <!-- Envíale un mensaje: -->
                <br />
                <CButton
                  class
                  name="Whatsapp"
                  size="md"
                  color="xing"
                  target="_blank"
                  @click.prevent="whatsapp('finding')"
                >
                  <CIcon size="lg" :name="'cib-Whatsapp'" /> Whatsapp
                </CButton>
              </b-col>

              <b-col lg="6" class="text-center">
                <!-- Número de celular: {{pet.phone_number}} -->
                <br />
                <a :href="`tel:${pet.phone_number}`">
                  <b-button size="md">
                    <CIcon size="lg" :name="'cilPhone'" /> Llamar
                  </b-button>
                </a>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col lg="4" offset-lg="4" v-if="pet" style="text-align:center;">
                <b-form-group>
                  <img
                    :src="pet.url ? pet.url : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'"
                    alt
                    class="img-thumbnail rounded mx-auto d-block"
                  />
                  <!-- <img
                    :src="pet.url ? pet.url : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'"
                    alt
                    class="profile-user-image"
                  />-->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <strong>Nombre:</strong>
                <br />
                {{pet.name}}
              </b-col>

              <b-col md="6">
                <strong>Raza:</strong>
                <br />
                {{pet.breed.nombre}}
              </b-col>

              <b-col lg="12">
                <br />
              </b-col>

              <b-col md="6">
                <strong>Fecha de nacimiento:</strong>
                <br />
                {{pet.dof}}
              </b-col>

              <b-col md="6">
                <strong>Observaciones a tener en cuenta:</strong>
                <br />
                {{pet.obs}}
              </b-col>
            </b-row>
          </CCardBody>
          <CCardFooter>
            <b-row v-if="pet.vet != null">
              <b-col lg="12" style="text-align:center;">
                <h5>Datos de veterinaria</h5>
              </b-col>

              <b-col lg="12">
                <p></p>
              </b-col>

              <b-col md="6">
                <strong>Veterinaria:</strong>
                <br />
                {{pet.vet}}
              </b-col>
              <b-col md="6">
                <strong>Teléfono:</strong>
                <br />
                {{pet.vetPhone}}
              </b-col>
              <b-col md="6">
                <strong>Dirección:</strong>
                <br />
                {{pet.vetAddress}}
              </b-col>
            </b-row>
          </CCardFooter>
        </CCard>
        <b-row>
          <b-col lg="1" style="text-align:center;">
            <img src="@/assets/images/logo.png" width="48px" alt="logo" class="logo-login" />
          </b-col>
          <b-col lg="11" style="text-align:left;">
            Para conocer las características y funcionalidades de la placa QR
            <b-link @click="goTo('serviceDetail')">has clic aquí</b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import GoQrcode from "go-qrcode";
import ReverseMd5 from "reverse-md5";
import Loading from "vue-loading-overlay";
import TagRegister from "./TagRegister";
// import HashDetect from 'hash-detector';
import decimal from "hexadecimal-to-decimal";
import { isHexadecimal } from "is-hexadecimal";
import moment from "moment";
export default {
  name: "Profile",
  props: ["id"],
  components: {
    Loading,
    TagRegister
  },
  data() {
    return {
      loading: true,
      userProfile: null,
      idDecrypted: null,
      pet: {
        id: null,
        name: null,
        breed: null,
        url: null,
        phone_number: null,
        client: null,
        obs: null
      },
      wp: "https://api.whatsapp.com/send?phone=",
      infoModal: false,
      flagPetProfile: false,
      userPets: [],
      flagTagNotRegistered: false,
      flagAssociateTag: false,
      flagTagActivated: false,
      flagIdIsNotHash: false,
      petsToSelect: [],
      petSelected: "",
      optionsPetsToSelect: [],
      error: false,
      errors: [],
      // Map attributes
      center: { lat: 0.0, lng: 0.0 },
      markers: [
        //user
        {
          position: { lat: 0.0, lng: 0.0 },
          label: "",
          draggable: true,
          title: "Casa"
        }
      ]
    };
  },
  validations: {
    petSelected: {
      required
    }
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);
    // console.log(this.userProfile)

    if (this.id != null) {
      // let isHex =  isHexadecimal(this.id)
      this.idDecrypted = decimal.decimal(this.id);
      // console.log(this.id)
      // console.log(isHex)
      if (isNaN(this.idDecrypted)) {
        // if(!isHex){
        // console.log('1')
        this.flagIdIsNotHash = true;
        this.loading = false;
      } else {
        // console.log('2')
        this.idDecrypted = decimal.decimal(this.id);
        // console.log('HexToDec: ', this.idDecrypted)
        this.loading = false;
        this.getPetProfile();
      }
    }
  },
  methods: {
    getPetProfile() {
      this.$store
        .dispatch("fetchTagProfile", this.idDecrypted)
        .then(tagProfile => {
          // console.log(tagProfile);
          //user has inserted another id that is not in database
          if (tagProfile == null) {
            // console.log("tagId not registered");
            this.loading = false;
            this.flagTagNotRegistered = true;
          } else {
            //tagId is new and not associated to any pet yet
            if (tagProfile.pet == null) {
              // console.log("Placa nueva sin registrar");
              // user logged?
              if (this.userProfile != null) {
                //validation to know if there are userPets
                this.userPets = this.userProfile.pets;
                this.petsToSelect = this.userPets.filter(
                  pet => pet.tag == "No"
                );
                if (this.petsToSelect.length > 0) {
                  // console.log("Debes seleccionar la mascota a la que deseas asociar");
                  this.petsToSelect.forEach(petToSelect => {
                    let item = {
                      value: petToSelect.id,
                      text: petToSelect.name
                    };
                    this.optionsPetsToSelect.push(item);
                  });
                  this.loading = false;
                  this.flagAssociateTag = true;
                }
                // theUser has not pets free to select
                else {
                  this.loading = false;
                  this.infoModal = true;
                }
              } else {
                // console.log('Debes realizar los pasos')
                this.loading = false;
                this.infoModal = true;
              }
            } else {
              //show profile
              this.pet = tagProfile.pet;
              let phone_number = this.pet.phone_number.replace(" ", "");
              this.pet.phone_number = phone_number
                .replace(" ", "")
                .replace("+", "");
              this.wp = this.wp + this.pet.phone_number;
              // console.log(this.pet);
              this.loading = false;
              this.flagPetProfile = true;
              //catch user postition GPS
              this.$getLocation({}).then(coordinates => {
                this.markers[0].position = coordinates;
                this.center = coordinates;
                this.mailPetFound();
              });
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    whatsapp(subject) {
      let message = "";
      if (subject == "finding") {
        message =
          this.wp +
          "&text=Hola, encontré a tu mascota " +
          this.pet.name +
          ". Acabo de ver su perfil en la plataforma de Go Guau.";
      }
      if (subject == "help") {
        this.wp = this.wp + "573194115792";

        message =
          this.wp +
          "&text=Hola, la placa con código: " +
          this.id +
          " aparece como no registrada. Por favor su ayuda.";
      }
      window.open(message, "_blank");
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errors = [
          "Debes seleccionar una mascota para asociar con la tarjeta"
        ];
      } else {
        this.error = false;
        console.log("A activar");
        this.updatePetTag();
      }
    },
    updatePetTag() {
      const data = {
        id: this.petSelected,
        tag: this.idDecrypted
      };
      this.loading = true;
      let editPetTagFunc = this.$firebase
        .functions()
        .httpsCallable("editPetTag");
      editPetTagFunc(data)
        .then(result => {
          const data = result.data.data;
          const activationDate = data.activationDate;
          const dateEndTagSubscription = data.dateEndTagSubscription;
          this.flagAssociateTag = false;
          this.flagTagActivated = true;
          this.mailTagActivation(activationDate, dateEndTagSubscription);
          this.getPetProfile();
        })
        .catch(error => {
          console.log(error);
        })
        .finally((this.loading = false));
    },
    goTo(to) {
      if (to == "serviceDetail") {
        this.$router.replace("/services/profile/3");
      }
    },
    updateCoordinates(location) {
      this.markers[0].position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
    },
    mailPetFound() {
      // https://www.google.com/maps/place/2.460580,-76.591503/@2.460580,-76.591503
      let gpsUrl =
        "https://www.google.com/maps/place/" +
        this.center.lat +
        "," +
        this.center.lng +
        "/@" +
        this.center.lat +
        "," +
        this.center.lng;

      let data = {
        clientName: this.pet.client.nombre,
        clientId: this.pet.client.id,
        petName: this.pet.name,
        gpsUrl: gpsUrl,
        accuracy: this.center.accuracy
      };
      // console.log(data);

      let mailPetFoundFunct = this.$firebase
        .functions()
        .httpsCallable("mailPetFound");

      mailPetFoundFunct(data)
        .then(result => {
          // console.log('correo enviado: ', result)
        })
        .catch(error => {
          // console.log('error enviando correo')
          console.log(error);
        });
    },
    mailTagActivation(activationDate, dateEndTagSubscription) {
      const data = {
        activationDate: moment(activationDate).format("YYYY-MM-DD"),
        dateEndTagSubscription: moment(dateEndTagSubscription).format(
          "YYYY-MM-DD"
        ),
        clientEmail: this.userProfile.email,
        clientName: this.userProfile.nombre,
        tagId: this.idDecrypted
      };
      // console.log('mailTagActivationData: ', data)
      let notifyTagActivation = this.$firebase
        .functions()
        .httpsCallable("mailTagActivation");
      notifyTagActivation(data)
        .then(result => {
          // console.log(result)
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.profile-user-image {
  border-radius: 50%;
  border: 2px solid white;
  width: 13rem;
  height: 13rem;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  background-color: white;
}
</style>